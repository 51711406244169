import { IModal } from '@/components';
import router from '@/router';
import store from '@/store';
import {
  IInput, IDatePicker, ITextarea, ICheckbox, ISelect,
} from '@/components/form/index';
import { ISession } from '@/models/session';
import { ITranslations } from '@/translations/index';

export interface INewSessionInputs {
  name: IInput;
  start_date: IDatePicker;
  end_date: IDatePicker;
  color: IInput;
  description: ITextarea;
  entrance_capacity: IInput;
  external_link: IInput;
  streaming_link: IInput;
  image: IInput;
  order: IInput;
  registration_capacity: IInput;
  private: ICheckbox;
  hide_on_calendar: ICheckbox;
  language: ISelect;
  embedded: ICheckbox;
  chat: ICheckbox;
}

export const form: Partial<ISession> = {
  name: '',
  start_date: '',
  end_date: '',
  color: '',
  description: '',
  entrance_capacity: 0,
  external_link: '',
  order: 0,
  registration_capacity: 0,
  private: false,
  streaming_link: '',
  hide_on_calendar: false,
  chat: true,
  embedded: true,
  _translations: {
    en: {
      _name: '',
      external_link: '',
      description: '',
    },
    es: {
      _name: '',
      external_link: '',
      description: '',
    },
    pt: {
      _name: '',
      external_link: '',
      description: '',
    },
  },
};

export const sessionLanguages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'pt', label: 'Portuguese' },
];

export const inputs = () => {
  const translations = store.getters.getTranslations as ITranslations;

  const inputs: Partial<INewSessionInputs> = {
    language: {
      id: 'new-session-language',
      name: 'new_session_language',
      label: 'Outros idiomas',
      options: [
        ...sessionLanguages,
        ...[{ value: 'default', label: 'Use default only', checked: true },
        ]],
      value: 'default',
    },

    name: {
      id: 'new-session-name',
      name: 'new_session_name',
      value: '',
      label: translations.exhibitors.sessions.inputs.name,
      placeholder: translations.exhibitors.sessions.inputs.name_placeholder,
      type: 'text',
    },
    start_date: {
      id: 'new-session-start-date',
      name: 'new_session_start_date',
      value: '',
      label: translations.exhibitors.sessions.inputs.start_date,
    },
    end_date: {
      id: 'new-session-end-date',
      name: 'new_session_end_date',
      value: '',
      label: translations.exhibitors.sessions.inputs.end_date,
    },
    color: {
      id: 'new-session-color',
      name: 'new_session_color',
      value: '',
      label: translations.exhibitors.sessions.inputs.color,
      placeholder: translations.exhibitors.sessions.inputs.color_placeholder,
      type: 'text',
    },
    description: {
      id: 'new-session-description',
      name: 'new_session_description',
      value: '',
      label: translations.exhibitors.sessions.inputs.description,
      placeholder: translations.exhibitors.sessions.inputs.description_placeholder,
    },
    entrance_capacity: {
      id: 'new-session-entrance-capacity',
      name: 'new_session_entrance_capacity',
      value: '',
      label: translations.exhibitors.sessions.inputs.entrance_capacity,
      placeholder: translations.exhibitors.sessions.inputs.entrance_capacity_placeholder,
      type: 'number',
    },
    external_link: {
      id: 'new-session-external-link',
      name: 'new_session_external_link',
      value: '',
      label: translations.exhibitors.sessions.inputs.external_link,
      placeholder: translations.exhibitors.sessions.inputs.external_link_placeholder,
      message: translations.exhibitors.sessions.inputs.external_link_warning_message,
      type: 'text',
    },
    streaming_link: {
      id: 'new-session-streaming_link',
      name: 'new_session_streaming_link',
      value: '',
      label: translations.exhibitors.sessions.inputs.streaming_link,
      placeholder: translations.exhibitors.sessions.inputs.streaming_link_placeholder,
      message: translations.exhibitors.sessions.inputs.streaming_link_warning_message,
      type: 'text',
    },
    image: {
      id: 'new-session-image',
      name: 'new_session_image',
      value: '',
      label: translations.exhibitors.sessions.inputs.image,
      placeholder: translations.exhibitors.sessions.inputs.image_placeholder,
      type: 'file',
    },
    order: {
      id: 'new-session-order',
      name: 'new_session_order',
      value: '',
      label: translations.exhibitors.sessions.inputs.order,
      placeholder: translations.exhibitors.sessions.inputs.order_placeholder,
      type: 'number',
    },
    registration_capacity: {
      id: 'new-session-registration-capacity',
      name: 'new_session_registration_capacity',
      value: '',
      label: translations.exhibitors.sessions.inputs.registration_capacity,
      placeholder: translations.exhibitors.sessions.inputs.registration_capacity_placeholder,
      type: 'number',
    },
    private: {
      id: 'new-session-private',
      name: 'new_session_private',
      label: translations.exhibitors.sessions.inputs.private_session,
      message: translations.exhibitors.sessions.private_session_warning,
      value: false,
    },
    hide_on_calendar: {
      id: 'new-session-hide_on_calendar',
      name: 'new_session_hide_on_calendar',
      label: translations.exhibitors.sessions.inputs.hide_on_calendar,
      value: false,
    },
    chat: {
      id: 'new-session-chat',
      name: 'new_session_chat',
      label: translations.exhibitors.sessions.enable_chat,
      value: false,
    },
    embedded: {
      id: 'new-session-embedded',
      name: 'new_session_embedded',
      label: translations.exhibitors.sessions.embed_meeting,
      message: translations.exhibitors.sessions.embed_meeting_detail,
      value: false,
    },
  };
  return inputs;
};

export const modal: IModal = {
  isLarge: false,
  isActive: true,
  isCard: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/manage-exhibitor/${store.getters.getContextExhibitor.id}/sessions`,
    );
  },
};
