























































































































// @ is an alias to /src

import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import formatDate from '@/utils/formatDate';
import { ISession, ISessionRegistration } from '@/models/session';
import { getSessionRegistrations } from '@/api/sessions';
import store from '@/store';
import { getVisitorById } from '@/api/visitors';
import { IVisitor } from '@/models/visitor';
import { modal } from '../index';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
  },
  data() {
    return {
      registrations: [] as ISessionRegistration[],
      modal: { ...modal },
      session: {} as ISession | undefined,
      visitors: [] as Array<IVisitor & { registration: ISessionRegistration }>,
      formatDate: formatDate,
      loading: false,
      sessionId: 0,
      event: 0,
      exhibitor: 0,
    };
  },
  setup() {
    const { translations } = useContext();
    const sessions = computed(() => store.getters.getSessions as ISession[]);
    return {
      translations,
      sessions,
    };
  },
  created() {
    this.modal = { ...modal };
    this.sessionId = parseInt(this.$route.params.session, 10);
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.getSessionStatus();
    if (
      this.sessions.length > 0 &&
      this.sessions.some((session) => session.id === this.sessionId)
    ) {
      this.session = this.sessions.find((session) => session.id === this.sessionId);
    } else {
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setSessions') {
          this.session = this.sessions.find((session) => session.id === this.sessionId);
        }
      });
    }
  },
  methods: {
    getSessionStatus() {
      getSessionRegistrations({
        session: this.sessionId,
        event: this.event,
      }).then(
        (response) => {
          this.registrations = response.data.results;
          this.registrations.forEach(async (registration) => {
            const response = await getVisitorById(registration.attending);
            const visitor = response.data;
            if (visitor.id) {
              this.visitors.push({
                ...visitor,
                registration,
              });
            }
          });
        },
        (error) => {
          console.log(error);
          // Could not retrieve registration. Enable registration.
        },
      );
    },

    inviteStatus(status: number) {
      if (status === 1) {
        return this.translations.common.registered;
      }
      if (status === 2) {
        return this.translations.common.registered;
      }
      if (status === 3) {
        return this.translations.common.registered;
      }
      if (status === 4) {
        return this.translations.common.registered;
      }
      if (status === 5) {
        return this.translations.common.pending;
      }
      if (status === 6) {
        return this.translations.common.accepted;
      }
      if (status === 7) {
        return this.translations.common.refused;
      }

      return status;
    },
  },
});
